.intro {
  max-width: 900px;
}

.own-tooltip {
  pointer-events: all;
  border-bottom: 1px dotted #000;
  display: inline-block;
  position: relative;
}

.own-tooltip .own-tooltiptext {
  visibility: hidden;
  color: #000;
  text-align: left;
  z-index: 2;
  background-color: #d3d3d3;
  border-radius: 10px;
  width: 250px;
  padding: 10px;
  position: absolute;
}

.own-tooltip:hover .own-tooltiptext {
  visibility: visible;
}

.align-right {
  text-align: right;
  color: green;
}

.ausgetrunken {
  cursor: not-allowed;
  pointer-events: none;
}

.ausgetrunken .flipper {
  opacity: .5;
}

.overlay-svg {
  z-index: 1;
  display: block;
  position: absolute;
  left: 40px;
  transform: rotate(349deg);
}

/*# sourceMappingURL=shop.9325325a.css.map */
