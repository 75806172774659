.intro {
  max-width: 900px;
}

.own-tooltip {
  /* Container for our tooltip */
  position: relative;
  display: inline-block;
  pointer-events: all;
  border-bottom:1px dotted black;
}

.own-tooltip .own-tooltiptext {
  /* This is for the tooltip text */
  visibility: hidden;
  width: 250px;
  background-color: lightgray;
  color: black;
  text-align: left;
  padding: 10px;
  border-radius: 10px; /* This defines tooltip text position */
  position: absolute;
  z-index: 2;
}

.own-tooltip:hover .own-tooltiptext {
  /* Makes tooltip text visible when text is hovered on */
  visibility: visible;
}

.align-right {
  text-align: right;
  color: green
}

.ausgetrunken {
  cursor: not-allowed;
  pointer-events: none;
}

.ausgetrunken .flipper {
  opacity: 0.5;
}

.overlay-svg {
  position: absolute;
  transform: rotate(349deg);
  left: 40px;
	display: block;
  z-index: 1;
}